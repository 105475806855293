<template>
  <div class="extra-curricular">

<header class="inner-head">
      <div class="flex">
        <h2 class="inner-head-title">Extra Curricular</h2>

                <ul class="subnav flex-grow">
               <li v-for="term in termDropdown" :key="term.key"
               class="inner-head-list-item hover:opacity-50 hover:text-action-blue">
            <button 
              @click="termSelected = term"
              class="focus:outline-none"
              v-bind:class="[termSelected.key == term.key ? 'active' : '']"
            >
              {{term.name}}
            </button>
          </li>
            
        </ul>

        <ul class="pt-1 right-0">
                 <li>
          <button
            type="button"
            class="action-button hover:opacity-75"
            @click="addNew()"
          >  
          <i class="fas fa-plus"></i>
           Add New</button>
        </li>

        </ul>
      </div>
    </header>


        <div class="filter-bar">
        <ul class="actions">
           <li class="opacity-75">Search:</li>
        <li>
          <input
            type="search"
            class="          filter-bar-search focus:outline-none"
            name="search"
            v-model="search"
            placeholder="Enter search term..."
          />
        </li>
        
      </ul>
    </div>


    <div class="w-full overflow-x-hidden">

      <div v-if="errored" class="ml-10 mt-10 text-sm text-red-500">
        <p>
          We are not able to retrieve this information at the moment, please try
          back later
        </p>
      </div>
      <div
        style="width: 100%; text-align: center"
        class="spin-holder mt-4"
        v-if="loading"
      >
         <i class="spinner fa fa-spinner fa-spin"></i>
        <p>Loading Activities</p>
      </div>
      <template v-else>
        <!-- TABLE -->
        <table class="grid-content mt-8 mx-auto mb-6" v-if="componentLoaded">
          <thead>
            <th></th>
            <!-- SET TABLE HEADINGS - DAYS -->
            <th
              v-for="day in days"
              v-bind:key="day"
              class="text-gray-400 uppercase font-semibold pb-2"
            >
              {{ day }}
            </th>
          </thead>
          <tbody>
            <!-- SET SIDE HEADINGS - TIMEFRAME -->
            <tr
              v-for="time in timesframes"
              v-bind:key="time.title"
              class="h-48"
            >
              <td class="text-gray-500 text-right uppercase font-semibold w-10">
                <div class="transform -rotate-90 text-center">
                {{ time.title }}
                </div>
              </td>
              <!-- Inline -->
              <td
                v-for="(day, index) in days"
                v-bind:key="index"
                class="
                  bg-white
                  rounded-xl
                  border-8 border-gray-100
                  m-2
                  w-44
                  align-top
                "
              >
                <button
                  v-for="(activity, activity_index) in findActivities(
                    time,
                    day
                  )"
                  :key="activity_index"
                  class="
                    w-full
                    h-12
                    bg-opacity-25
                    mb-1
                    rounded
                    text-xs
                    hover:bg-gray-200
                  "
                  v-bind:class="getBackgroundColor(activity)"
                  @click="showActivity(activity)"
                >
                  {{ activity.title }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
            <div v-if="noResultsFound" class="ml-4 font-bold w-full cursor-pointer text-center" @click="addNew">
              <p class="mt-10 text-gray-400 text-sm w-full text-center">No extra curricular activities found. Please select another month or click to add a new activity.</p>
        </div>

      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ActivityService } from "@/services/activity.service";
import { termList, eventTypes, timesframes } from "../assets/js/utils";

const dropdown = () => import("@/components/Dropdown.vue");

import moment from "moment";
export default {
  name: "extra-curricular",
  components: {
    dropdown
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      currentAccess: "currentAccess",
    }),
    componentLoaded() {
      return this.activities.length > 0 && this.loading === false;
    },
    termDropdown() {
      var results = this.terms;
      this.termSelected = results[0];
      return results;
    },
    noResultsFound() {
      return !this.errored && !this.componentLoaded
    }
  },
  data() {
    return {
      errored: false,
      loading: true,
      showSearch: false,
      search: "",
      termSelected: {},
      activities: [],
      days: [],
      timesframes: timesframes(),
      terms: termList(),
      types: eventTypes(),
    };
  },
  created() {
    moment.locale("en-gb");
    this.days = moment.weekdays(true);
    this.termSelected = this.terms[0];
    this.fetchActivities();
  },
  methods: {
    fetchActivities() {
      ActivityService.getAll()
        .then((response) => (this.activities = response.data))
        .catch((err) => {
          console.log("Activities " + err);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    getBackgroundColor(activity) {
      let type = this.getProgramType(activity);
      return type != undefined ? type.class : "bg-red-200";
    },
    getProgramType(activity) {
      return this.types.find((t) => t.value === activity.session_type);
    },
    updateSelectedTerm(term) {
      this.termSelected = term;
    },
    setStyles(term) {
      return this.termSelected.key === term.key
        ? "bg-blue-300 text-white hover:bg-blue-200"
        : "bg-blue-200 text-blue-400 hover:bg-blue-300 ";
    },
    setListStyle() {
      return "rounded bg-" + this.currentUser.schoolBackground;
    },
    setSearch() {
      this.showSearch = !this.showSearch;
      this.search = "";
    },
    findActivities(time, day) {
      let vm = this;

      var filteredList = vm.activities.filter((item) => {
        var vm = this;
        return item.title.toLowerCase().includes(vm.search.toLowerCase());
      });

      let arr = filteredList.filter((a) => {
        const terms = a.term.split(",");
        const days = a.weekday.split(",");
        const times = a.timeframe.split(",");

        return (
          terms.includes(vm.termSelected.key) &&
          days.includes(day.toLowerCase()) &&
          times.includes(time.time_value)
        );
      });

      return arr;
    },
     addNew() {
      let activity = {};
      this.$router.push({
        name: "ActivityView",
        params: { activity: activity, newAddition: true },
      });
    },
    showActivity(activity) {
      this.$router.push({
        name: "ActivityView",
        params: { activity: activity, newAddition: false },
      });
    },

  },
};
</script>

