import { SchoolService } from "@/services/school.service";

const ActivityService = {
    getAll () {
        return SchoolService.getAll("activities")
    },

    add(payload) {
        return SchoolService.create("activities", payload)
    },

    update(object) {
        return SchoolService.update("activities", object.identifier, object)
    },

    delete(object) {
        return SchoolService.delete("activities", object.identifier, null)
    },

    // pupils

    getPupils(object) {
        return SchoolService.getAll("activities/" + object.identifier + "/pupils")
    },

    deletePupil(result, object) {
        return SchoolService.delete("activities/" + result.identifier + "/pupils", object.identifier, null) 
     },

     addPupil(payload) {
        return SchoolService.create("activities/pupils", payload)
    },
     
}

export { ActivityService };